.MuiInputBase-input {
  font-size: 12px;
}

.lock_icon_size {
  font-size: 16px;
}

.endAdornment {
  display: flex;
}
