body {
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  width: 100%;
  height: 100%;
}

*,
::after,
::before {
  box-sizing: border-box;
}

:root {
  --sidebar-width: 42px;
  --sidebar-active-width: 185px;
  --sidebar-font-color: fff;
  --sidebar-font-size: 0.625rem;
  --sidebar-selected-color: #5a7c90;
  --sidebar-hover-color: #547689;
  --sidebar-background-color: #44677b;
  --sidebar-padding: 12px;
  --sidebar-slide-time: 200ms;

  --header-height: 50px;

  --attribute-smart-filter-container-width: 210px;
}

/**
 Falback for bootstrap  important for login screen to work right now
 remove once the login screen is fixed
*/

.d-md-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* style to display a button */
.button-primary {
  background-color: #4f677b;
  color: #fff;
}

/* style to display button on hover */
.button-primary:hover {
  background-color: #4f677b;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.page-wrapper > div:first-child {
  background: white;
  height: 100% !important;
}
#sub-content {
  height: 100%;
}
#sub-content > div:first-child {
  margin-bottom: 0px !important;
}

#sub-content > .page-content {
  height: 100%;
  padding: 0px !important;
}


/* separator */
.vertical-separator {
  height: 1rem;
  margin: 0px 4px;
  border: 1px solid #d4d4d4;
}

/* layout:- margin left due to sidebar */

.layout {
  margin-left: 64px;
}

.menu-hamburger {
  display: flex;
  align-items: center;
  padding: 1rem 1.5rem;
  color: #fff;
}

.menu-hamburger:hover {
  cursor: pointer;
}

.menu-hamburger img {
  width: 120px;
  height: auto;
}

/* .Mui-selected:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 4px;
  background-color: #93bde9;
} */

/* Header */

.impact-platform-header {
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  right: 0;
  z-index: 800;
  position: sticky;
  height: 56px;
  background-color: #fff;
  box-shadow: 0px 4px 10px 0px #0000001f;
}

header.impact-platform-header.ontop {
  box-shadow: none;
}

nav.impact-platform-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  width: 100%;
}

.impact-platform-left-side {
  display: flex;
  align-items: center;
  gap: 8px;
}

.impact-platform-left-side-product-header {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #000;
}

.impact-platform-right-side {
  display: flex;
  align-items: center;
  gap: 8px;
}

/* notification */

.notification-container {
  position: relative;
}

.notification-container-popup {
  display: none;
  position: absolute;
  top: 45px;
  right: 20px;
  width: 480px;
  height: 480px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 0px 8px;
}

.show-notification {
  display: block;
}

.notification-container-popup-header {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: Poppins, Roboto, Helvetica, Arial, sans-serif;
  border-bottom: 1px solid rgb(239, 239, 239);
  height: 44px;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  padding: 12px 20px;
}

.notification-container-popup-close-btn {
  font-size: 12px;
  color: #758490;
  margin-bottom: -10px;
  cursor: pointer;
  cursor: pointer;
}

.notification-container-popup-sub-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  font-family: Poppins, Roboto, Helvetica, Arial, sans-serif;
}

.notification-container-popup-sub-header-right-side {
  display: flex;
  gap: 24px;
  align-items: center;
}

.notification-container-popup-sub-header-right-side-btn {
  color: rgb(0, 85, 175);
}

.notification-container-popup-body {
  padding: 12px 20px;
}
.notification-container-popup-body-list {
  display: flex;
  gap: 16px;
  align-items: center;
  background: #e5edf7;
  padding: 8px 10px;
  margin-left: -8px;
  border-radius: 4px;
}


.impact-sidebar-container .impact-sidebar-routes-list {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.impact-sidebar-container .impact-sidebar-routes-list::-webkit-scrollbar {
  display: none; 
}