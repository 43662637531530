/* border color - d7e2eb */
@import "Styles/variables.scss";
@import "Styles/mixins.scss";

.pagination-btns {
  margin-top: pxToRem(10);
}

.pagination-text {
  margin-top: pxToRem(15);
  color: $scorpion;
  @include font-size-subtitle1();
}

.pagination-slct-pgsize {
  margin-top: pxToRem(15);
}

.search-bar {
  margin-inline: 1vw;
}

.no-data-Container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-height: 5rem;
}

.btn-style {
  margin: pxToRem(3);
  border-radius: pxToRem(4);
  @include font-size-button();
}

.table-container {
  display: block;
}

tr:last-child td {
  border-bottom: 0;
}

.btns-block {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
}

.btns-block button {
  margin: pxToRem(5);
}

.rt-thead {
  overflow-y: scroll;
}

.table-header {
  color: $fireFlyLight;
  cursor: move;
  padding-block: 0.5%;
}
.table-header-fixed {
  cursor: pointer;
}
.setAllButton {
  padding: pxToRem(10);
}

.sorting-icon {
  cursor: pointer;
}

.sort-menu-option {
  flex-direction: row;
}

.sorted-indicator {
  margin-right: pxToRem(5);
  width: pxToRem(20);
  height: pxToRem(25);
}

.search-input {
  height: pxToRem(30);
  background: $white;
}
.table-toolbar {
  padding: pxToRem(10) 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.pagination-container {
  display: flex;
  justify-content: space-between;
}

.filter-icon {
  &:hover {
    cursor: pointer;
  }
}

.col-hovering-header {
  border: pxToRem(2) solid $slateGray;
}
.header-icons {
  display: flex;
  margin-left: pxToRem(4);
}

.header-text {
  overflow: hidden;
  text-overflow: ellipsis;
}

.table {
  border-spacing: 0;
  border: pxToRem(1) solid $botticelli;
  border-radius: pxToRem(5);
  .tr {
    :last-child {
      .td {
        border-bottom: 0;
      }
    }
  }

  .th {
    display: flex;
  }

  .td {
    color: $oxfordBlue;
  }

  .input-field-td {
    padding: 0.5rem 0.5rem 0.5rem 0rem !important;
  }
  .th,
  .td {
    align-items: center;
    border-bottom: pxToRem(1) solid $botticelli;
    justify-content: center;
    overflow: hidden;
    padding: 0.5rem;
    text-align: left;
    text-overflow: ellipsis;
    &.empty-footer {
      min-width: pxToRem(50) !important;
    }
  }
  .empty-footer {
    padding: 0.5rem;
    min-height: pxToRem(50);
    min-width: pxToRem(50) !important;
    border-bottom: pxToRem(1) solid $botticelli;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    background-color: $white;
  }
  .selectionbox {
    min-width: pxToRem(60) !important;
    padding-left: 0.5rem;
  }
  .selectcheckbox {
    display: flex !important;
  }
  .groupedCellBorder {
    border-right: solid 2px $botticelli;
  }

  .row-span-td {
    padding: 2rem 1rem 1rem;
    min-width: pxToRem(150);
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    background-color: $zirconLight;
  }
  .childRow {
    background-color: $white;
  }
  .childRowPagniation {
    background-color: $white;
    height: 5rem;
    border-bottom: pxToRem(1) solid $linkWaterLight;
    padding: 1rem;
  }

  &.sticky {
    overflow: auto;

    .header,
    .footer {
      position: sticky;
    }

    .header {
      top: 0;
      margin: 0;
      background-color: $linkWaterLight;
      text-align: center;
      white-space: nowrap !important;
      @include font-size-body2();
    }

    .body {
      background: $white;
      position: relative;
      z-index: 0;
      margin: 0;
      text-align: center;
      white-space: nowrap;
      @include font-size-subtitle1();
    }

    [data-sticky-td] {
      position: sticky;
    }
    [data-sticky-first-left-td] {
      box-shadow: 3px 0 5px -1px $botticelli;
    }
    [data-sticky-last-left-td] {
      box-shadow: 3px 0 5px -1px $botticelli;
    }

    [data-sticky-first-right-td] {
      box-shadow: -3px 0 5px -1px $botticelli;
    }

    .body {
      [data-sticky-td] {
        background-color: $white;
      }
      [data-sticky-last-left-td] {
        background-color: $white;
      }
    }
  }

  .header {
    .th,
    .td {
      background-color: $alabaster;
    }
  }

  .body {
    .th,
    .tr {
      background-color: $white;
    }
    .tr-checked {
      background-color: $aliceBlue;
    }
  }

  .footer {
    .th,
    .td,
    .empty-footer {
      border-bottom: none;
    }

    .td {
      line-height: 2;
    }
  }

  .icon-blue {
      font-size: pxToRem(25);
      color: $royalBlue;
      cursor: pointer;
  }
}
