.sub-rule-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.definition-input-div {
  display: flex;
}

.no-subrule-div {
  display: flex;
  justify-content: center;
  align-items: center;
}
