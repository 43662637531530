.filterContainer{
    padding: 0 1.5rem 1.5rem;
}

.filter-row{
display: flex;
flex-wrap: wrap;
justify-content: space-between;

}
.switch{
    margin-left: 1em;
    margin-right: 1em;

}
.filter-col{
width:22%;
margin-right: 1em;
margin-left: 1em;
margin-bottom: 1em;

}

.button{
    margin-left:0.6rem;
    text-transform:none;
    background-color:#41a6f7;
   
  }

